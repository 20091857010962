(function($, prodcat, generic) {
  prodcat.ui = prodcat.ui || {};
  prodcat.data = prodcat.data || {};
  /**
   * Adds SKUs to cart.
   * @param {Object} args arguments
   * @inheritdoc
   */
  prodcat.ui.addToCart = function(args) {
    var skuBaseId;
    if (args.skuData && args.skuData.SKU_BASE_ID) {
      skuBaseId = args.skuData.SKU_BASE_ID;
    } else if (args.skuBaseId) {
      skuBaseId = args.skuBaseId;
    } else {
      return;
    }

    var quantity;
    if (typeof args.quantity !== 'undefined') {
      quantity = args.quantity;
    } else {
      quantity = 1;
    }

    var catBaseId = '';
    if (args.skuData && args.skuData.PARENT_CAT_ID) {
      var matchResult = args.skuData.PARENT_CAT_ID.match('[0-9]+');
      if (matchResult) {
        catBaseId = matchResult[0];
      }
    }

    args.skus = args.skus || [skuBaseId];
    args.itemType = args.itemType || 'cart';
    args.QTY = quantity || args.QTY;
    args.INCREMENT = 1;
    // INCREMENT only needs to be true.

    // Conditionally add a CAT_BASE_ID key to the list of parameters to send
    var cbid = args.CAT_BASE_ID || catBaseId;
    if (cbid.length > 0) {
      args.CAT_BASE_ID = cbid;
    }

    if (!Drupal.settings.globals_variables?.enable_auto_replenish_spp && args?.itemType === 'replenishment') {
      delete args.itemType;
    }

    generic.checkout.cart.updateCart({
      params: args,
      onSuccess: function(r) {
        var resultData = r.getData();
        $(document).trigger('addToCart.success', [resultData]);
      },
      onFailure: function (ss) {
        /**
         * The ss.getMessages() sometimes return offer success messages instead of cart limit error
         * Since getMessages() in jsonrpc.js gives precendence to messages over errors
         * So, getError() is rendered and getMessages() is set as fallback
         */
        var errorObject = ss.getError();
        var errorObjectsArray = errorObject?.data?.messages || ss.getMessages();

        $(document).trigger('addToCart.failure', [errorObjectsArray]);
        var $errorMessageContainer = $('<div/>', {
          html: '<span class="cboxLoadedContent-error" data-test-id="max_error_message">' + errorObjectsArray[0].text + '</span>'
        });
        generic.overlay.launch({
          content: $errorMessageContainer,
          includeBackground: true,
          includeCloseLink: true,
          initialHeight: 0,
          height: 0,
          width: '50%',
          onComplete: function() {
            $(this).colorbox.resize();
          }
        });
      }
    });
  };

  /**
   * Adds a SKU to the user's primary favorites list.
   * @param {Object} args arguments for a SKU (numerical only, i.e. no 'SKU' prefix)
   * @inheritdoc
   */
  prodcat.ui.addToWishlist = function(args) {
    var params = {
      _SUBMIT: 'alter_collection',
      action: 'add'
    };
    if (args.action) {
      params.action = args.action;
    }

    var skuBaseId;
    if (args.skuData && args.skuData.SKU_BASE_ID) {
      skuBaseId = args.skuData.SKU_BASE_ID;
    } else if (args.skuBaseId) {
      skuBaseId = args.skuBaseId;
    } else {
      return;
    }
    params.SKU_BASE_ID = skuBaseId;

    if (args.skuData && args.skuData.PARENT_CAT_ID) {
      var matchResult = args.skuData.PARENT_CAT_ID.match('[0-9]+');
      if (matchResult) {
        params.CAT_BASE_ID = matchResult[0];
      }
    }

    generic.jsonrpc.fetch({
      method: 'rpc.form',
      params: [params],
      onSuccess: function(jsonRpcResponse) {
        var d = jsonRpcResponse.getData();
        var r = d.ac_results[0].result;
        var cr;

        if (params.action !== 'delete') {
          if (r.KEY === 'SKU_ALREADY_IN_COLLECTION.ADD_SKU.COLLECTION.SAVE') {
            cr = jsonRpcResponse.getCartResults();
            $(document).trigger('addToWishlist.exists', [cr, args.$el]);
          } else if (r.SUCCESS === 1 || r.KEY === 'SUCCESS.ADD_SKU.COLLECTION.SAVE') {
            cr = jsonRpcResponse.getCartResults();
            $(document).trigger('addToWishlist.success', [cr, args.$el]);
          }
        }
      },
      onFailure: function(ss) {
        var errorObjectsArray = ss.getMessages();
        $(document).trigger('addToWishlist.failure', [errorObjectsArray]);
      }
    });
  };
})(jQuery, window.prodcat = window.prodcat || {}, window.generic = window.generic || {});
